import React, { useState } from 'react'

import { Collapsible, Heading, Help, Paragraph } from '@te-digi/styleguide'

import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Content
      heading="Help"
      components={[{ component: Help }]}
      status={[{ type: 'accessible', version: '13.10.0' }]}
    >
      <Section>
        <Playground>
          <Help noMargin>
            <Paragraph>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
              posuere interdum sem. Quisque ligula eros ullamcorper quis,
              lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu.
            </Paragraph>
            <Paragraph noMargin>
              Sed arcu lectus auctor vitae, consectetuer et venenatis eget
              velit. Sed augue orci, lacinia eu tincidunt et eleifend nec lacus.
            </Paragraph>
          </Help>
        </Playground>
        <Playground>
          <Help
            noMargin
            variant="tip"
          >
            <Heading
              level={3}
              size={5}
            >
              Suspendisse facilisis nulla in lacinia laoreet
            </Heading>
            <Paragraph>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
              posuere interdum sem. Quisque ligula eros ullamcorper quis,
              lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu.
            </Paragraph>
            <Paragraph noMargin>
              Sed arcu lectus auctor vitae, consectetuer et venenatis eget
              velit. Sed augue orci, lacinia eu tincidunt et eleifend nec lacus.
            </Paragraph>
          </Help>
        </Playground>
        <Playground>
          <Help
            noMargin
            variant="tip"
          >
            <Collapsible
              inline
              isOpen={isOpen}
              label="Suspendisse facilisis nulla in lacinia laoreet"
              onToggle={() => setIsOpen(isOpen => !isOpen)}
            >
              <Paragraph>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
                posuere interdum sem. Quisque ligula eros ullamcorper quis,
                lacinia quis facilisis sed sapien. Mauris varius diam vitae
                arcu.
              </Paragraph>
              <Paragraph noMargin>
                Sed arcu lectus auctor vitae, consectetuer et venenatis eget
                velit. Sed augue orci, lacinia eu tincidunt et eleifend nec
                lacus.
              </Paragraph>
            </Collapsible>
          </Help>
        </Playground>
        <Playground>
          <Help
            noMargin
            variant="error"
          >
            <Paragraph noMargin>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
              posuere interdum sem. Quisque ligula eros ullamcorper quis,
              lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu.
            </Paragraph>
          </Help>
        </Playground>
        <Playground format="html">
          <>
            <div className="help mb-lg">
              <div className="help-accessories help-secondary">
                <IconHTMLExample
                  name="AlertCircleIcon"
                  size="lg"
                />
                <div className="help-line" />
              </div>
              <div>
                <h3 className="h5">
                  Suspendisse facilisis nulla in lacinia laoreet
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
                  posuere interdum sem. Quisque ligula eros ullamcorper quis,
                  lacinia quis facilisis sed sapien. Mauris varius diam vitae
                  arcu.
                </p>
                <p className="mb-0">
                  Sed arcu lectus auctor vitae, consectetuer et venenatis eget
                  velit. Sed augue orci, lacinia eu tincidunt et eleifend nec
                  lacus.
                </p>
              </div>
            </div>
            <div className="help">
              <div className="help-accessories help-danger">
                <IconHTMLExample
                  name="AlertCircleIcon"
                  size="lg"
                />
                <div className="help-line" />
              </div>
              <div>
                <p className="text-danger mb-0">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
                  posuere interdum sem. Quisque ligula eros ullamcorper quis,
                  lacinia quis facilisis sed sapien. Mauris varius diam vitae
                  arcu.
                </p>
              </div>
            </div>
          </>
        </Playground>
      </Section>
      <Section title="Saavutettavuus">
        <Paragraph>
          Komponentti ei sisällä semantiikkaa. Ruudunlukija lukee sisällön
          tavallisena tekstinä, ei mitenkään korostettuna. Värit ja ikonit eivät
          välity ruudunlukijalle, joten visuaalisesti ilmaistun merkityksen
          (virhe, huomautus, vinkki) tulee käydä ilmi myös tekstisisällöstä.
        </Paragraph>
      </Section>
    </Content>
  )
}

export default Page
